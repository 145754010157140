import { useEffect, useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import DataTable from 'react-data-table-component';
import classnames from 'classnames'

import Icon from '../utils/Icon'
import { fetcher, formatNumber, getLocalDate } from '../../utils'

export default function GamblerCommissionModal({ show, axios, user, isDarkTheme, onHide, onError }) {
  if (window && show) {
    const isDarkTheme = settings.tema_padrao === 1;
    const body = window.document.querySelector("body");

    if (!isDarkTheme) {
      body?.classList?.add("light-mode");
      body?.classList?.remove("dark-mode");
    } else {
      body?.classList?.add("dark-mode");
      body?.classList?.remove("light-mode");
    }
  }

  const [commissions, setCommissions] = useState([])

  useEffect(() => {
    fetcher(axios, '/api/comissoes')
      .then(({ data }) => {
        if (data) {
          setCommissions(data.comissoes)
        }
      }).catch(onError)

    return () => setCommissions([])
  }, [])

  const columns = [
    {
      name: 'Nome',
      selector: row => row.nome,
      sortable: true,
    },
    {
      name: 'Nome de Usuário',
      selector: row => row.login,
    },
    {
      name: 'Data de Cadastro',
      selector: row => row.data ? getLocalDate(row.data).milliseconds() : '',
      format: row => row.data ? getLocalDate(row.data).format('DD/MM/Y') : '',
      sortable: true,
      sortFunction: (a, b) => getLocalDate(b.data).valueOf() - getLocalDate(a.data).valueOf()
    },
    {
      name: 'Comissões de Depósitos',
      selector: row => +row.comissao,
      format: row => `R$ ${formatNumber(row.comissao)}`,
      sortable: true,
    },
  ];

  if (user.comissao_resultado) {
    columns.push({
      name: 'Comissões de Apostas',
      selector: row => row.comissao_resultado,
      format: row => `R$ ${formatNumber(row.comissao_resultado)}`,
      sortable: true,
    })
  }

  const paginationComponentOptions = {
    rowsPerPageText: 'Usuários por página',
    rangeSeparatorText: 'de',
  };

  return (
    <Modal show={show} centered size='lg'>
      <Modal.Header >
        <Modal.Title className={classnames({ 'text-white': isDarkTheme })}>Minhas Comissões</Modal.Title>
        <Button variant={isDarkTheme ? 'dark' : 'light'} className={classnames('text-right mt-1', { 'text-white': isDarkTheme })} onClick={() => onHide()}>
          <Icon name="times" />
        </Button>
      </Modal.Header>
      <Modal.Body className="px-0 py-0">
        <DataTable
          theme={isDarkTheme ? 'dark' : 'light'}
          columns={columns}
          data={commissions}
          fixedHeader={true}
          paginationPerPage={10}
          paginationRowsPerPageOptions={[10, 20, 30, 40, 50, 100]}
          paginationComponentOptions={paginationComponentOptions}
          noDataComponent={<span style={{ padding: '20px' }}>Você ainda não possui comissão de afiliados</span>}
          defaultSortFieldId={3}
          pagination
          striped
        />
      </Modal.Body>
    </Modal>
  )
}
