import { useEffect, useRef, useState } from "react";
import Image from "next/image";
import styles from "./index.module.css";
import Icon from "../../utils/Icon";

type CardCasinoProps = {
  key?: string | number;
  name: string;
  provider: string;
  imgSrc: string;
  play: () => void;
  demo?: () => void;
  hasDemo: boolean;
};

const GameCard = ({
  name,
  provider,
  imgSrc,
  play,
  hasDemo,
  demo,
}: CardCasinoProps) => {
  const cardRef = useRef<HTMLDivElement>(null);
  const [card, setCard] = useState<Element>(null);

  useEffect(() => {
    setCard(cardRef?.current?.children[0]);
  }, [cardRef]);

  return (
    <div
      ref={cardRef}
      className={styles.Wrapper}
      onMouseEnter={(event) =>
        setTimeout(
          () =>
            event.target === card
              ? card?.setAttribute("class", `${styles.ButtonWrapper}`)
              : card?.setAttribute(
                "class",
                `${styles.ButtonWrapper} ${styles.Touched}`
              ),
          0
        )
      }
      onTouchStart={(event) =>
        setTimeout(
          () =>
            event.target === card
              ? card?.setAttribute("class", `${styles.ButtonWrapper}`)
              : card?.setAttribute(
                "class",
                `${styles.ButtonWrapper} ${styles.Touched}`
              ),
          200
        )
      }
      onMouseLeave={() =>
        card?.setAttribute("class", `${styles.ButtonWrapper}`)
      }
    >
      <div className={styles.ButtonWrapper}>
        {hasDemo && (
          <button
            type="button"
            className={`${styles.Button} ${styles.Secondary}`}
            onClick={() => demo()}
          >
            <Icon style="fas" name="vial" />
            Testar
          </button>
        )}
        <button type="button" className={styles.Button} onClick={() => play()}>
          <Icon style="fas" name="play" />
          Jogar
        </button>
      </div>
      <div className={styles.Thumbnail}>
        <Image
          className={styles.Image}
          loader={() => imgSrc}
          src={imgSrc}
          objectFit="cover"
          layout="fill"
          quality={75}
          blurDataURL="/images/cassino/blurred-image.jpeg"
          placeholder="blur"
          alt={`Capa do jogo ${name}`}
        />
        <div className={styles.Legend}>
          <span>{provider}</span>
          <legend>{name}</legend>
        </div>
      </div>
    </div>
  );
};

export default GameCard;
