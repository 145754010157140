import { useState } from "react";
import CasinoGameModal from "../../cassino/CasinoGameModal";
import Carousel from "react-multi-carousel";

import "react-multi-carousel/lib/styles.css";
import Icon from "../../utils/Icon";
import styles from "./index.module.css";
import GameCard from "../../Casino/GameCard";
import DefaultSpinner from "../../layout/DefaultSpinner";

const CasinoFavorites = ({
  axios,
  games,
  isDarkTheme,
  isMobile,
  setPage,
  setSport,
  hasDemo,
  userLevel,
  refreshBalance,
}) => {
  const [selectedGame, setSelectedGame] = useState(null);

  if (typeof window !== "undefined") {
    import("alertifyjs");
  }

  const handleCasinoClick = () => {
    setPage("sports");
    setSport("casino");
  };

  const handlePlay = (game, userLevel) => {
    if (!userLevel) {
      // @ts-ignore
      alertify.alert("Atenção", "Você precisa de uma conta para jogar.");
      //.set({ title: "Ops!" });
    } else if (userLevel !== 3) {
      // @ts-ignore
      alertify.alert("Cassino disponível apenas para apostadores.");
      //.set({ title: "Ops!" });
    } else {
      setSelectedGame(Object.assign({ type: "start" }, game));
    }
  };

  const customArrow = (type: "left" | "right") => {
    const isLeft = type === "left";

    return (
      <button
        type="button"
        title={isLeft ? "Jogo Anterior" : "Jogo Seguinte"}
        aria-label={isLeft ? "Jogo Anterior" : "Jogo Seguinte"}
        className={
          styles.SlideButton +
          " " +
          (isLeft ? styles.SlideButtonLeft : styles.SlideButtonRight)
        }
      >
        <Icon name={isLeft ? "chevron-left" : "chevron-right"} />
      </button>
    );
  };

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      slidesToSlide: 2,
      items: 4,
    },
    laptop: {
      breakpoint: { max: 1024, min: 820 },
      slidesToSlide: 2,
      items: 3,
    },
    tablet: {
      breakpoint: { max: 820, min: 468 },
      slidesToSlide: 1,
      items: 2,
    },
    mobile: {
      breakpoint: { max: 468, min: 0 },
      slidesToSlide: 1,
      items: 1,
    },
  };

  return (
    <section className={styles.Wrapper}>
      <div>
        <h3 className={styles.Title}>Jogos Populares</h3>
        <button
          type="button"
          className={styles.Button}
          onClick={() => handleCasinoClick()}
        >
          ver todos
        </button>
      </div>
      {games ? (
        <Carousel
          ssr={true}
          infinite={true}
          customLeftArrow={customArrow("left")}
          customRightArrow={customArrow("right")}
          itemAriaLabel="game card"
          responsive={responsive}
          itemClass={styles.SlideItem}
          partialVisbile={false}
        >
          {games?.map((game) => (
            <GameCard
              key={game.id}
              name={game.name}
              provider={game.provider_name}
              imgSrc={game.image || "./images/cassino/game.jpg"}
              hasDemo={hasDemo}
              play={() => handlePlay(Object.assign({ type: "full" }, game), userLevel)}
              demo={() =>
                setSelectedGame(Object.assign({ type: "demo" }, game))
              }
            />
          ))}
        </Carousel>
      ) : (
        <div className={styles.Spinner}>
          <DefaultSpinner
            height={`${isMobile ? 8 : 10}vh`}
            isDarkTheme={isDarkTheme}
          />
        </div>
      )}
      <CasinoGameModal
        isMobile={isMobile}
        isDarkTheme={isDarkTheme}
        game={selectedGame}
        axios={axios}
        setSelectedGame={setSelectedGame}
        refreshBalance={refreshBalance}
      />
    </section>
  );
};

export default CasinoFavorites;
