import classnames from 'classnames'
import { useState, useEffect } from 'react'
import { Alert, Button, Col, Form, InputGroup, Row, Table } from 'react-bootstrap'
import moment from 'moment'

import BankDepositModal from '../modals/BankDepositModal'
import Icon from '../utils/Icon'
import { formatNumber, fetcher, getDepositMethod } from '../../utils'

export default function Deposits({ axios, user, settings, isMobile, setLoading, onError, isDarkTheme, loadUser }) {
  const [selectedDeposit, setSelectedDeposit] = useState(0)
  const [deposits, setDeposits] = useState([])
  const [depositValue, setDepositValue] = useState('')
  const [showModalDeposit, setShowModalDeposit] = useState(false)
  const [useCoupon, setUseCoupon] = useState(false)
  const [coupon, setCoupon] = useState('')

  useEffect(() => {
    loadDeposits()
  }, [])

  const loadDeposits = () => {
    setLoading(true)

    fetcher(axios, '/api/depositos')
      .then(({data}) => {
        setLoading(false)
        if (data) setDeposits(data)
      }).catch(onError)
  }

  const handleDeposit = () => {
    setLoading(true)

    const value = parseFloat(depositValue.replace(',', '.'))

    if (value == 0) {
      setLoading(false)
      alertify.alert('Atenção!', 'Faça um depósito com um valor válido')
      return
    }

    const body = {
      valor: value
    }

    if (useCoupon && coupon) {
      body['cupom'] = coupon
    }

    fetcher(axios, '/api/deposito', body)
      .then(({ data }) => {
        if (!data.resposta) {
          setLoading(false)
          alertify.alert(data.mensagem, data.descricao)

          return
        }

        handleModalDeposit(data.deposito)
        loadDeposits()
        setDepositValue('')
        setUseCoupon(false)
        setCoupon('')

        if (typeof kwaiq !== 'undefined') {
          kwaiq.track('addToCart')
          kwaiq.page('addToCart')
        }

        if (typeof ttq !== 'undefined') {
          ttq.track('addToCart')
          ttq.page('addToCart')
        }
      }).catch(onError)
  }

  const handleModalDeposit = (deposit) => {
    setSelectedDeposit(deposit)
    setShowModalDeposit(true)
  }

  const updateDepositStatus = (id, status) => {
    setLoading(true)

    fetcher(axios, '/api/statusDeposito', {status, deposito: id})
      .then(({ data }) => {
        if (!data.resposta) {
          setLoading(false)
          alertify.alert(data.mensagem, data.descricao)

          return
        }

        loadDeposits()
      }).catch(onError)
  }

  const isAutomaticDeposit = () => {
    return settings.pix_automatico
  }

  const renderInput = () => {
    if (isMobile) {
      return (
        <>
          <div className="d-block">
            <InputGroup>
              <InputGroup.Prepend>
                <InputGroup.Text className={classnames(['border-0', {'bg-dark': isDarkTheme }])}>
                  <Icon name="usd-circle" />
                </InputGroup.Text>
              </InputGroup.Prepend>
              <Form.Control
                className={classnames(['border-0', { 'bg-dark text-light': isDarkTheme, 'bg-light': !isDarkTheme }])}
                placeholder="Informe o valor"
                value={depositValue}
                onChange={({target}) => setDepositValue(target.value)}
                inputMode="numeric"
                pattern="[0-9*]"
              />
            </InputGroup>
          </div>
          <div className="d-block mt-2">
            <Button block variant="primary" onClick={() => depositValue && handleDeposit()} disabled={useCoupon && !coupon}>
              <Icon name="hand-holding-usd" margin />
              Depositar Valor
            </Button>
          </div>
          <div className="d-block mt-2">
            <Form.Check
              checked={useCoupon}
              custom
              className={classnames(['custom-checkbox', { 'text-light': isDarkTheme }])}
              type="checkbox"
              label="Utilizar cupom"
              onChange={({ target }) => setUseCoupon(target.checked)}
              id="cupom"
            />
          </div>
          {useCoupon && <div className="d-block mt-2">
            <InputGroup>
              <InputGroup.Prepend>
                <InputGroup.Text className={classnames(['border-0', { 'bg-dark': isDarkTheme }])}>
                  <Icon name="ticket" />
                </InputGroup.Text>
              </InputGroup.Prepend>
              <Form.Control
                className={classnames(['border-0', { 'bg-dark text-light': isDarkTheme, 'bg-light': !isDarkTheme }])}
                placeholder="Informe o código do cupom"
                value={coupon}
                onChange={({ target }) => setCoupon(target.value)}
              />
            </InputGroup>
          </div>}
        </>
      )
    }

    return (
      <>
        <Row className="mb-2">
          <Col>
            <InputGroup>
              <InputGroup.Prepend>
                <InputGroup.Text className={classnames(['border-0', {'bg-dark': isDarkTheme }])}>
                  <Icon name="usd-circle" />
                </InputGroup.Text>
              </InputGroup.Prepend>
              <Form.Control
                className={classnames(['border-0', { 'bg-dark text-light': isDarkTheme, 'bg-light': !isDarkTheme }])}
                placeholder="Informe o valor"
                value={depositValue}
                onChange={({target}) => setDepositValue(target.value)}
              />
            </InputGroup>
          </Col>
          <Col>
            <Button block variant="primary" onClick={() => depositValue && handleDeposit()} disabled={useCoupon && !coupon}>
              <Icon name="hand-holding-usd" margin />
              Depositar valor
            </Button>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <Form.Check
              checked={useCoupon}
              custom
              className={classnames(['custom-checkbox', { 'text-light': isDarkTheme }])}
              type="checkbox"
              label="Utilizar cupom"
              onChange={({ target }) => setUseCoupon(target.checked)}
              id="cupom"
            />
          </Col>
          {useCoupon && <>
            <Col md={6} className="mt-2">
              <InputGroup>
                <InputGroup.Prepend>
                  <InputGroup.Text className={classnames(['border-0', { 'bg-dark': isDarkTheme }])}>
                    <Icon name="ticket" />
                  </InputGroup.Text>
                </InputGroup.Prepend>
                <Form.Control
                  className={classnames(['border-0', { 'bg-dark text-light': isDarkTheme, 'bg-light': !isDarkTheme }])}
                  placeholder="Informe o código do cupom"
                  value={coupon}
                  onChange={({ target }) => setCoupon(target.value)}
                />
              </InputGroup>
            </Col>
          </>}
        </Row>
      </>
    )
  }

  const renderHeader = () => {
    if (user.nivel != 3)
      return <Alert.Heading>Área Disponível Apenas Para Apostadores!</Alert.Heading>

    if (!settings.habilitar_deposito)
      return <Alert.Heading>Área Indisponível Temporariamente!</Alert.Heading>

    return (
      <>
        <Alert.Heading>Depositar dinheiro</Alert.Heading>
        <hr />
        <p className={isMobile ? "mb-0" : ""}>
          Seu saldo atual é <b>R$ {formatNumber(user.saldo)}</b>
        </p>
        <p className="mb-0">
          {settings.mensagem_deposito || 'Para os pedidos de depósito, a confirmação será efetuada em até 72h úteis'}
        </p>
        <hr />
        {renderInput()}
      </>
    )
  };

  return (
    <>
      <Alert className={classnames({ 'alert-dark2': isDarkTheme })}>
        {renderHeader()}
      </Alert>
      {deposits.length > 0 && <Table responsive={isMobile} variant={isDarkTheme ? 'dark' : '' } striped className="rounded mt-3">
        <thead>
          <tr>
            <th>Origem</th>
            <th>Valor</th>
            <th>Data</th>
            <th>Situação</th>
            <th>Ações</th>
          </tr>
        </thead>
        <tbody>
          {deposits.map(deposit => (
            <tr key={deposit.id}>
              <td>{getDepositMethod(deposit.metodo)}</td>
              <td>R$ {formatNumber(deposit.valor)}</td>
              <td>{moment(deposit.data).format('DD/MM/YYYY')}</td>
              <td>{deposit.status}</td>
              <td>
                {deposit.status == "Pendente" && <Button variant="primary" onClick={() => handleModalDeposit(deposit)} className="mr-3">
                  {isAutomaticDeposit() ?
                  <>
                    <Icon name="money-bill" /> Pagar
                  </>
                  : 'Enviar Comprovante'}
                </Button>}
                {deposit.status != "Finalizado" && deposit.status != "Cancelado" && <Button variant="secondary" onClick={() => updateDepositStatus(deposit.id, 4)}>
                  <Icon name="times" /> Cancelar Pedido
                </Button>}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>}
      <BankDepositModal
        show={showModalDeposit}
        deposit={selectedDeposit}
        setDeposit={setSelectedDeposit}
        axios={axios}
        settings={settings}
        isDarkTheme={isDarkTheme}
        onUpdateDepositStatus={updateDepositStatus}
        onHide={() => setShowModalDeposit(false)}
        loadUser={loadUser}
        loadDeposits={loadDeposits}
        isAutomaticDeposit={isAutomaticDeposit()}
        setLoading={setLoading}
      />
    </>
  )
}
