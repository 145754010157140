import { useState, useEffect } from 'react'
import { Alert, Button, Col, Form, InputGroup, Row, Table } from 'react-bootstrap'
import classnames from 'classnames'
import MaskedInput from 'next-maskedinput'
import InputMask from 'react-input-mask'

import ModalUserBank from '../modals/ModalUserBank'
import Icon from '../utils/Icon'
import { formatNumber, getLocalDate, fetcher } from '../../utils'

export default function Withdraws({
  axios,
  user,
  settings,
  isMobile,
  setLoading,
  loadUser,
  onError,
  isDarkTheme
}) {
  const userAccount = user.conta_bancaria
  const userPix = user.pix

  const [showBankModal, setShowBankModal] = useState(false)
  const [withdraws, setWithdraws] = useState([])
  const [withdrawValue, setWithdrawValue] = useState('')
  const [pix, setPix] = useState(userPix || settings.saque_automatico || false)
  const [keyOwner, setKeyOwner] = useState((userPix && userPix.nome) || (!settings.habilitar_cadastro_pix && user.nome) || '')
  const [keyType, setKeyType] = useState((userPix && userPix.tipo) || 0)
  const [key, setKey] = useState((userPix && userPix.chave) || '')

  useEffect(() => {
    loadWithdraws()
  }, [])

  const loadWithdraws = () => {
    setLoading(true)

    fetcher(axios, '/api/saques')
      .then(({ data }) => {
        setLoading(false)

        if (data) {
          setWithdraws(data)
        }
      }).catch(onError)
  }

  const handleWithdraw = (confirm = false) => {
    const value = parseFloat(('' + withdrawValue).replace(',','.'))

    if (value < settings.saque_minimo) {
      alertify.alert('Atenção!', `O valor mínimo para saques é de R$ ${formatNumber(settings.saque_minimo)}!`)

      return
    }

    if (value > user.saldo) {
      alertify.alert('Atenção!', 'Você não tem saldo suficiente para realizar o saque!')

      return
    }

    if (value > settings.saque_maximo) {
      alertify.alert('Atenção!', `O valor máximo para saques é de R$ ${formatNumber(settings.saque_maximo)}!`)

      return
    }

    if (!userAccount && !pix) {
      setShowBankModal(true)

      return
    }

    if (pix && !keyType) {
      alertify.alert('Atenção!', 'Escolha o tipo da sua chave Pix')

      return
    }

    if (pix && keyType && settings.habilitar_cadastro_pix && !key) {
      alertify.alert('Atenção!', 'Adicione a sua chave Pix')

      return
    }

    let body

    if (pix && keyType) {
      body = { pix: true, pix_nome: keyOwner, tipo_chave: keyType, valor: value }

      if (settings.habilitar_cadastro_pix) {
        body['chave'] = key
      }
    } else {
      const { agencia, banco_id: banco, conta, tipo_id: conta_tipo } = userAccount;
      body = { agencia, banco, conta, conta_tipo, valor: value }
    }

    if (confirm) {
      body['confirmar'] = 1
    }

    setLoading(true)

    fetcher(axios, '/api/saque', body)
      .then(({ data }) => {
        setLoading(false)

        if (data.reconfirmar) {
          alertify.confirm(data.mensagem, data.descricao, () => handleWithdraw(true), () => {})
            .set({ padding: true, labels: { ok: 'Confirmar', cancel: 'Cancelar' }})

          return
        }

        if (!data.resposta) {
          alertify.alert(data.mensagem, data.descricao)

          return
        }

        loadWithdraws()
        loadUser()
        alertify.alert('Sucesso!', 'Sua solicitação foi realizada com sucesso.')
      }).catch(onError)
  }

  const onSubmitSuccess = () => {
    loadUser()
    setShowBankModal(false)
  }

  const handleKeyType = (type) => {
    setKeyType(type)
    setKey('')
  }

  const updateWithdrawStatus = (id, status) => {
    setLoading(true)

    fetcher(axios, '/api/statusSaque', {status, saque: id})
      .then(({ data }) => {
        if (!data.resposta) {
          setLoading(false)
          alertify.alert(data.mensagem, data.descricao)

          return
        }

        loadWithdraws()
        loadUser()
      }).catch(onError)
  }

  const renderPixGroup = () => {
    if (!settings.habilitar_cadastro_pix) {
      return (
        <>
          <div className="d-block">
            <Form.Group>
              <Form.Label className={classnames({ 'text-white': isDarkTheme })}>Tipo de Chave</Form.Label>
              <Form.Control
                as="select"
                className={classnames(['border-0', { 'bg-dark text-light': isDarkTheme, 'bg-light': !isDarkTheme }])}
                onChange={({ target }) => handleKeyType(target.value)}
                value={keyType}
              >
                <option value="">Escolha um tipo de chave</option>
                <option value="1">CPF/CNPJ - {user.cpf}</option>
                {user.telefone && <option value="2">Celular - {user.telefone}</option>}
                <option value="4">E-mail - {user.email}</option>
              </Form.Control>
              <small>Em caso de divergência, entre em contato com o atendimento.</small>
            </Form.Group>
          </div>
        </>
      )
    }

    return (
      <>
        <div className="d-block">
          <Form.Group>
            <Form.Label className={classnames({ 'text-white': isDarkTheme })}>Tipo de Chave</Form.Label>
            <Form.Control
              as="select"
              className={classnames(['border-0', { 'bg-dark text-light': isDarkTheme, 'bg-light': !isDarkTheme }])}
              onChange={({ target }) => handleKeyType(target.value)}
              value={keyType}
            >
              <option value="">Escolha um tipo de chave</option>
              <option value="1">CPF/CNPJ</option>
              <option value="2">Celular</option>
              <option value="3">Chave Aleatória</option>
              <option value="4">E-mail</option>
            </Form.Control>
          </Form.Group>
        </div>
        {!!keyType && keyType > 0 && <div className="d-block">
          <Form.Group>
            <Form.Label className={classnames({ 'text-white': isDarkTheme })}>Chave Pix</Form.Label>
            {renderPixControl(+keyType)}
          </Form.Group>
        </div>}
      </>
    )
  }

  const renderPixControl = (type) => {
    if (type === 1) {
      return (
        <InputMask
          className={classnames(['form-control border-0', { 'bg-dark text-light': isDarkTheme, 'bg-light': !isDarkTheme }])}
          mask={key.length < 15 ? "999.999.999-999" : '99.999.999/0001-99'}
          maskChar={null}
          value={key.toString()}
          onChange={({target}) => setKey(target.value)}
          placeholder="Informe a chave"
        />
      )
    }

    if (type === 2) {
      return (
        <Form.Control
          as={MaskedInput}
          className={classnames(['border-0', { 'bg-dark text-light': isDarkTheme, 'bg-light': !isDarkTheme }])}
          placeholder="Informe a chave"
          value={key.toString()}
          onChange={({target}) => setKey(target.value)}
          mask="(11) 111111111"
        />
      )
    }

    if (type === 3) {
      return (
        <Form.Control
          as={MaskedInput}
          className={classnames(['border-0', { 'bg-dark text-light': isDarkTheme, 'bg-light': !isDarkTheme }])}
          placeholder="Informe a chave"
          value={key}
          onChange={({target}) => setKey(target.value)}
          mask="AAAAAAAA-AAAA-AAAA-AAAA-AAAAAAAAAAAA"
          formatCharacters= {{
            'A': {
              validate(char) { return /[0-9a-fA-F]/.test(char)},
              transform(char) { return char.toLowerCase() }
            }
          }}
        />
      )
    }

    if (type === 4) {
      return (
        <Form.Control
          className={classnames(['border-0', { 'bg-dark text-light': isDarkTheme, 'bg-light': !isDarkTheme }])}
          placeholder="Informe a chave"
          value={key}
          onChange={({target}) => setKey(target.value)}
          type="email"
          required
        />
      )
    }

    return null
  }

  const renderInput = () => {
    if (isMobile) {
      return (
        <>
          {settings.habilitar_saque_pix && !settings.saque_automatico && <Form.Check
            checked={pix}
            custom
            className={classnames(['custom-checkbox', { 'text-light': isDarkTheme }])}
            type="checkbox"
            label="Recebimento por Pix"
            onChange={({ target }) => setPix(target.checked)}
            id="pix"
          />}
          {pix && <>
            <div className="d-block">
              <Form.Group>
                <Form.Label className={classnames({ 'text-white': isDarkTheme })}>Nome Completo ou Razão Social</Form.Label>
                <Form.Control
                  className={classnames(['border-0', { 'bg-dark text-light': isDarkTheme, 'bg-light': !isDarkTheme }])}
                  placeholder="Informe o nome"
                  value={keyOwner}
                  onChange={({ target }) => setKeyOwner(target.value)}
                  disabled={!settings.habilitar_cadastro_pix}
                />
              </Form.Group>
            </div>
            {renderPixGroup()}
          </>}
          <div className="d-block">
            <InputGroup>
              <InputGroup.Prepend>
                <InputGroup.Text className={classnames(['border-0', { 'bg-dark': isDarkTheme }])}>
                  <Icon name="usd-circle" />
                </InputGroup.Text>
              </InputGroup.Prepend>
              <Form.Control
                className={classnames(['border-0', { 'bg-dark text-light': isDarkTheme, 'bg-light': !isDarkTheme }])}
                placeholder="Informe o Valor"
                value={withdrawValue}
                onChange={({target}) => setWithdrawValue(target.value)}
                inputMode="numeric"
                pattern="[0-9*]"
              />
              <InputGroup.Append>
                <InputGroup.Text
                  className={classnames(['border-0', { 'bg-dark text-light': isDarkTheme }])}
                  onClick={() => setWithdrawValue(user.saldo)}
                >
                  Máx.
                </InputGroup.Text>
              </InputGroup.Append>
            </InputGroup>
          </div>
          <div className="d-block mt-2">
            <Button block variant="primary" onClick={() => handleWithdraw()}>
              <Icon name="hand-holding-usd" margin />
              Sacar valor
            </Button>
          </div>
        </>
      )
    }

    return (
      <>
        {userAccount && <>
            <Table variant={isDarkTheme ? 'dark': ''} striped className="rounded mb-4">
              <tbody>
                <tr>
                  <td>Banco</td>
                  <td>{userAccount.banco}</td>
                </tr>
                <tr>
                  <td>Agência</td>
                  <td>{userAccount.agencia}</td>
                </tr>
                <tr>
                  <td>Conta</td>
                  <td>{userAccount.conta}</td>
                </tr>
                <tr>
                  <td>Tipo de Conta</td>
                  <td>{userAccount.conta_tipo}</td>
                </tr>
                <tr>
                  <td colSpan="2">
                    <Button block variant="primary" onClick={() => setShowBankModal(true)}>
                      <Icon name="hand-holding-usd" margin />
                      Alterar Dados Bancários
                    </Button>
                  </td>
                </tr>
              </tbody>
            </Table>
          </>
        }
        {settings.habilitar_saque_pix && !settings.saque_automatico && <Form.Check
          checked={pix}
          custom
          className={classnames(['custom-checkbox', { 'text-light': isDarkTheme }])}
          type="checkbox"
          label="Recebimento por Pix"
          onChange={({target}) => setPix(target.checked)}
          id="pix"
        />}
        {pix && <>
          <div className="d-block">
            <Form.Group>
              <Form.Label className={classnames({ 'text-white': isDarkTheme })}>Nome Completo ou Razão Social</Form.Label>
              <Form.Control
                className={classnames(['border-0', { 'bg-dark text-light': isDarkTheme, 'bg-light': !isDarkTheme }])}
                placeholder="Informe o nome"
                value={keyOwner}
                onChange={({target}) => setKeyOwner(target.value)}
                disabled={!settings.habilitar_cadastro_pix}
              />
            </Form.Group>
          </div>
          {renderPixGroup()}
        </>}
        <Row>
          <Col>
            <InputGroup>
              <InputGroup.Prepend>
                <InputGroup.Text className={classnames(['border-0', { 'bg-dark': isDarkTheme }])}>
                  <Icon name="usd-circle" />
                </InputGroup.Text>
              </InputGroup.Prepend>
              <Form.Control
                className={classnames(['border-0', { 'bg-dark text-light': isDarkTheme, 'bg-light': !isDarkTheme }])}
                placeholder="Informe o Valor"
                value={withdrawValue}
                onChange={({ target }) => setWithdrawValue(target.value)}
              />
              <InputGroup.Append>
                <InputGroup.Text
                  className={classnames(['border-0', { 'bg-dark text-light': isDarkTheme }])}
                  onClick={() => setWithdrawValue(user.saldo)}
                >
                  Máx.
                </InputGroup.Text>
              </InputGroup.Append>
            </InputGroup>
          </Col>
          <Col>
            <Button block variant="primary" onClick={() => handleWithdraw()}>
              <Icon name="hand-holding-usd" margin />
              Sacar valor
            </Button>
          </Col>
        </Row>
      </>
    )
  }

  const renderHeader = () => {
    if (user.nivel != 3) {
      return <Alert.Heading>Área Disponível Apenas Para Apostadores!</Alert.Heading>
    }

    if (!settings.habilitar_saque) {
      return <Alert.Heading>Área Indisponível Temporariamente!</Alert.Heading>
    }

    return (
      <>
        <Alert.Heading>Sacar dinheiro</Alert.Heading>
        <hr />
        <p className={isMobile ? "mb-0" : ""}>
          Seu saldo atual é <b>R$ {formatNumber(user.saldo)}</b>
        </p>
        <p className="mb-0">
          {settings.mensagem_saque || 'Para os pedidos de saques, os pagamentos serão efetuados em até 72h úteis'}
        </p>
        <hr />
        {renderInput()}
      </>
    )
  }

  return (
    <>
      <Alert className={classnames(['m-2', { 'alert-dark2': isDarkTheme }])}>
        {renderHeader()}
        {withdraws.length > 0 &&
          <Table responsive={isMobile} variant={isDarkTheme ? 'dark' : ''} striped className="rounded mt-3">
            <thead>
              <tr>
                <td></td>
                <td>Dados Bancários</td>
                <td>Valor</td>
                <td>Situação</td>
                <td>Data</td>
                <td>Ações</td>
              </tr>
            </thead>
            <tbody>
              {withdraws.map(withdraw => (
                <tr key={withdraw.id}>
                  <td>{withdraw.id}</td>
                  <td>{withdraw.descricao}</td>
                  <td>R$ {formatNumber(withdraw.valor)}</td>
                  <td>{withdraw.status}</td>
                  <td>{getLocalDate(withdraw.data, settings.fuso_horario).format('DD/MM/YYYY HH:mm')}</td>
                  <td>
                    {withdraw.status != 'Finalizado' && withdraw.status != 'Cancelado' && <Button variant="secondary" onClick={() => updateWithdrawStatus(withdraw.id, 4)}>
                      <Icon name="times" /> Cancelar Pedido
                    </Button>}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        }
      </Alert>
      <ModalUserBank
        axios={axios}
        show={showBankModal}
        isDarkTheme={isDarkTheme}
        settings={settings}
        userAccount={userAccount}
        onSubmitSuccess={onSubmitSuccess}
        onError={onError}
        onHide={() => setShowBankModal(false)}
      />
    </>
  )
}
