import { useState } from 'react'
import { Button, Col, InputGroup, Form, Nav, Row, Tab } from 'react-bootstrap'
import classnames from 'classnames'

import AccountTable from './AccountTable'
import ExtractTable from './ExtractTable'
import Deposits from './Deposits'
import Withdraws from './Withdraws'
import Icon from '../utils/Icon'
import Navbar from "../SportsPage/Navbar";

export default function GamblerAccount({
  apiUrl,
  axios,
  setLoading,
  user,
  sport,
  onChangeSport,
  isMobile,
  onItemClick,
  settings,
  loadUser,
  onError,
  isDarkTheme,
  depositLinkClicked
}) {
  const [key, setKey] = useState(depositLinkClicked ? 'depositar' : 'conta')

  const renderContent = () => {
    return (
      <div className="pt-3 px-3">
        <Tab.Container activeKey={key} onSelect={(selectedKey) => setKey(selectedKey)}>
          <Nav variant="pills" className={classnames({ 'nav-justified': !isMobile })}>
            <Nav.Item className={classnames({ 'bg-dark': isDarkTheme, 'w-100 mt-2': isMobile })}>
              <Nav.Link eventKey="conta">
                <Icon name="user" margin />
                Conta
              </Nav.Link>
            </Nav.Item>
            <Nav.Item className={classnames({ 'bg-dark': isDarkTheme, 'w-100 mt-2': isMobile, 'ml-2': !isMobile })}>
              <Nav.Link eventKey="extrato">
                <Icon name="file-invoice-dollar" margin />
                Extrato
              </Nav.Link>
            </Nav.Item>
            {settings.modulo_apostador && <>
              <Nav.Item className={classnames({ 'bg-dark': isDarkTheme, 'w-100 mt-2': isMobile, 'ml-2': !isMobile })}>
                <Nav.Link eventKey="depositar">
                  <Icon name="box-usd" margin />
                  Depositar
                </Nav.Link>
              </Nav.Item>
              <Nav.Item className={classnames({ 'bg-dark': isDarkTheme, 'w-100 mt-2': isMobile, 'ml-2': !isMobile })}>
                <Nav.Link eventKey="sacar">
                  <Icon name="hand-holding-usd" margin />
                  Sacar
                </Nav.Link>
              </Nav.Item>
            </>}
          </Nav>
          <Tab.Content className="mt-3">
            <Tab.Pane eventKey="conta">
              <AccountTable
                user={user}
                axios={axios}
                isMobile={isMobile}
                isDarkTheme={isDarkTheme}
                settings={settings}
                onError={onError}
              />
            </Tab.Pane>
            <Tab.Pane eventKey="extrato">
              <ExtractTable
                user={user}
                isMobile={isMobile}
                setLoading={setLoading}
                axios={axios}
                onError={onError}
                settings={settings}
                isDarkTheme={isDarkTheme}
              />
            </Tab.Pane>
            {settings.modulo_apostador && <>
              <Tab.Pane eventKey="depositar">
                <Deposits
                  axios={axios}
                  user={user}
                  settings={settings}
                  isMobile={isMobile}
                  setLoading={setLoading}
                  onError={onError}
                  isDarkTheme={isDarkTheme}
                  loadUser={loadUser}
                />
              </Tab.Pane>
              <Tab.Pane eventKey="sacar">
                <Withdraws
                  axios={axios}
                  user={user}
                  settings={settings}
                  isMobile={isMobile}
                  setLoading={setLoading}
                  loadUser={loadUser}
                  onError={onError}
                  isDarkTheme={isDarkTheme}
                />
              </Tab.Pane>
            </>}
          </Tab.Content>
        </Tab.Container>
      </div>
    )
  }

  if (isMobile) {
    return (
      <div className="home-m-wrapper">
        <div className="scroll-mobile">
          <Navbar active={sport} axios={axios} onItemClick={onItemClick} onSelect={onChangeSport} />
          {renderContent()}
        </div>
      </div>
    )
  }

  return (
    <>
      <Navbar active={sport} axios={axios} onItemClick={onItemClick} onSelect={onChangeSport} />
      <div className="home-wrapper">
        {renderContent()}
      </div>
    </>
  )
}
