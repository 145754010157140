import Icon from "components/utils/Icon";
import styles from "./index.module.css";

function handleClick(event) {
  document
    ?.querySelectorAll(`.${styles.Active}`)
    ?.forEach((li) => {
      li?.classList?.remove(styles.Active);
      li?.classList?.add('text-light');
    });

  (event?.target as HTMLLIElement)?.classList?.add(styles.Active);
  (event?.target as HTMLLIElement)?.classList?.add('text-light');
}

function Categories({
  isLive,
  categories,
  queryProvider,
  setQueryCategory,
  setCurrentPage,
  isDarkTheme,
}) {
  return (
    categories?.length > 0 && (
      <ul className={styles.Wrapper}>
        <li
          className={`${styles.CategoryPill} ${isLive ? '' : styles.Active} ${isDarkTheme ? 'text-light' : 'text-dark'}`}
          style={{
            backgroundColor: isDarkTheme ? "var(--dark)" : "var(--white)"
          }}
          onClick={(e) => {
            handleClick(e);
            setQueryCategory((prev) => {
              if (prev !== 0) {
                setCurrentPage(1);
              }
              return 0;
            });
          }}
        >
          {queryProvider > 0 ? (
            "Todos"
          ) : (
            <>
              <Icon name="star" />
              Populares
            </>
          )}
        </li>
        {categories?.map((category) => {
          return (
            <li
              key={category?.id}
              className={`${styles.CategoryPill} ${isLive && category?.id === 15 ? styles.Active : ''} ${isDarkTheme ? 'text-light' : 'text-dark'}`}
              style={{
                backgroundColor: isDarkTheme ? "var(--dark)" : "var(--white)",
              }}
              onClick={(e) => {
                handleClick(e);
                setQueryCategory((prev) => {
                  if (category?.id !== prev) {
                    setCurrentPage(1);
                  }
                  return category?.id;
                });
              }}
            >
              {category?.name}
            </li>
          );
        })}
      </ul>
    )
  );
}

export default Categories;
