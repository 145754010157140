import styles from "./index.module.css";

const CategoriesCards = ({ setPage, setSport, isDarkTheme, sports, hasCasino, hasLive }) => {
  const handleClick = (sport: string) => {
    setPage("sports");
    setSport(sport);
  };

  const theme = isDarkTheme ? " bg-dark text-light" : " bg-light text-dark";

  return (
    <nav className={styles.Wrapper}>
      {hasCasino ? (
        <>
          <button
            type="button"
            className={styles.Card + theme}
            onClick={() => handleClick("casino")}
          >
            <i className="fas fa-dice"></i>
            Cassino
          </button>
          <button
            type="button"
            className={styles.Card + theme}
            onClick={() => handleClick("casino-live")}
          >
            <i className="fas fa-money-bill-alt"></i>
            Cassino Ao Vivo
          </button>
        </>
      ) : null}
      <button
        type="button"
        className={styles.Card + theme}
        onClick={() => handleClick(sports[0]?.key || "soccer")}
      >
        <i className="fas fa-running"></i>
        Esportes
      </button>
      {hasLive ? (
        <button
          type="button"
          className={styles.Card + theme}
          onClick={() => handleClick("live")}
        >
          <i className="fas fa-futbol"></i>
          Esportes Ao Vivo
        </button>
      ) : null}
    </nav>
  );
};

export default CategoriesCards;
