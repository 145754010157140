import { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { fetcher } from "../../utils";

import Scroll from "../Casino/Scroll";
import Filters from "../Casino/Filters";
import CasinoGameModal from "../cassino/CasinoGameModal";

import styles from "../Casino/index.module.css";
import DefaultSpinner from "components/layout/DefaultSpinner";
import BannersCarrousel from "components/layout/BannersCarrousel";

const GAMES_PER_PAGE = 20;

interface IQuery {
  mobile: number;
  pagina: number;
  categoria?: number;
  provedor?: number;
  favoritos?: number;
  nome?: string;
}

const Casino = ({
  isLive,
  axios,
  settings,
  user,
  isMobile,
  isDarkTheme,
  refreshBalance,
}) => {
  const [games, setGames] = useState([]);
  const [providers, setProviders] = useState([]);
  const [queryName, setQueryName] = useState("");
  const [categories, setCategories] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [hasNextPage, setHasNextPage] = useState(true);
  const [casinoLive, setCasinoLive] = useState(isLive);
  const [queryCategory, setQueryCategory] = useState(0);
  const [queryProvider, setQueryProvider] = useState(0);
  const [selectedGame, setSelectedGame] = useState(null);
  const [currentPage, setCurrentPage] = useState<number>(1);

  function mountQuery() {
    const params: IQuery = {
      mobile: +isMobile,
      pagina: currentPage,
    };

    if (queryCategory > 0) {
      params.categoria = queryCategory;
    }
    if (queryProvider > 0) {
      params.provedor = queryProvider;
    }
    if (!+queryProvider && !+queryCategory) {
      params.favoritos = 1;
    }
    if (queryName !== "") {
      params.nome = queryName;
    }

    return new URLSearchParams(params as unknown as string[][]);
  }

  async function loadGames(body: URLSearchParams) {
    setIsLoading(() => body.toString().includes("pagina=1"));

    const { data } = await fetcher(axios, `/api/jogos_cassino?${body}`);

    if (!data.resposta) {
      // @ts-ignore
      alertify.alert(data.mensagem, data.descricao);
      return;
    }
    if ((casinoLive && queryCategory > 0) || (!casinoLive)) {
      setGames((prev) => currentPage === 1 ? data.jogos : prev.concat(data.jogos));
    }

    setHasNextPage(currentPage < Math.ceil(data.total_jogos / GAMES_PER_PAGE));

    if (data.categorias) setCategories(data.categorias);
    if (data.provedores) setProviders(data.provedores);
    if (casinoLive) {
      setQueryCategory(15);
      setCasinoLive(false);
    }

    setIsLoading(false);
  }

  useEffect(() => {
    const fetchGames = async () => await loadGames(mountQuery());
    fetchGames();
  }, [queryCategory, queryProvider, queryName, currentPage]);

  return (
    <>
      {settings?.banners_cassino ? <BannersCarrousel banners={settings?.banners_cassino} /> : null}
      <Container
        className={styles.Container + " mt-3 overflow-x-hidden"}
        fluid
        id="casino-container"
      >
        <Filters
          isLive={isLive}
          isDarkTheme={isDarkTheme}
          queryName={queryName}
          queryProvider={queryProvider}
          providers={providers}
          categories={categories}
          setQueryName={setQueryName}
          setCurrentPage={setCurrentPage}
          setQueryCategory={setQueryCategory}
          setQueryProvider={setQueryProvider}
        />
        {
          isLoading ? (
            <div>
              <DefaultSpinner
                height={`${isMobile ? 8 : 10}vh`}
                isDarkTheme={isDarkTheme}
              />
            </div>
          ) : (
            <Scroll
              isMobile={isMobile}
              isDarkTheme={isDarkTheme}
              games={games}
              user={user}
              settings={settings}
              hasNextPage={hasNextPage}
              setSelectedGame={setSelectedGame}
              setCurrentPage={setCurrentPage}
            />
          )
        }
      </Container>
      <CasinoGameModal
        isMobile={isMobile}
        isDarkTheme={isDarkTheme}
        game={selectedGame}
        axios={axios}
        setSelectedGame={setSelectedGame}
        refreshBalance={refreshBalance}
      />
    </>
  );
};

export default Casino;
